<template>
  <div class="add-hospital-admin">
    <h1>Add Hospital Recruiters</h1>
      <div v-if="getHospitalRecruitersList.length === 5">You can only Add 5 recruiters per hospital admin. To see registered recruiters click here:
        <a href="/hospital-recruiters-list"><u>Registered Recruiters List</u></a>
      </div>
      <div v-else>
        <AddHospitalAdmin :customer="2" @submit="create" />
      </div>
  </div>

</template>

<script>
import router from '@/router'
import AddHospitalAdmin from "@/containers/Hospital/AddHospitalAdmin";
import { mapGetters, mapActions } from "vuex";
import { getRoleType } from "@/helpers/helper";

export default {
  name: "AddHospitalRecruiters",
  components: {
    AddHospitalAdmin
  },
  computed: {
    ...mapGetters(["getHospitalRecruitersList"]),
  },
  mounted() {
      this.FetchHospitalRecruitersList();
  },
   methods: {
    ...mapActions(['addHospitalAdmin', 'FetchHospitalRecruitersList']),
    create(data) {
      const payload = {
        'first_name': data.first_name,
        'surname': data.surname,
        'contact_phone': data.contact_phone,
        'email': data.email,
        'password': data.password,
        // 'dob': data.dob,
        // 'hospital_name': data.hospital_name ? data.hospital_name: "" ,
        // 'website': data.website ? data.website : "",
        'user_name': data.email,
        'role_type_id': getRoleType(),
        'is_active': true,
        'suspended': false,
        set_passwd_on_login: true
      }
     this.addHospitalAdmin(payload)
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  @media all and (min-width: 760px) {
    width: 50%;
    margin: auto;
  }
}
</style>
